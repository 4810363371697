<template>
  <div class="footer">
    <div class="footer_menu">
      <router-link class="footer_menu_item" v-for="(item, index) in menuItems" :to="{ path: item.path }" :key="index" :class="getActive(item)">
        <i class="icon" :class="item.iconClass" />
        <div>{{ item.name }}</div>
        <div v-if="dataList != '' && index == 2" class="countIcon">
          {{ dataList.length }}
        </div>
        <div v-if="chatCount > 0 && index == 3" class="countIcon2">
          {{ chatCount }}
        </div>
      </router-link>
    </div>
    <div class="footer_menu_row">
      <router-link class="footer_menu_item" v-for="(item, index) in menuItems" :to="{ path: item.path }" :key="index" :class="{ active: item.iconClass === $route.name, close: showMenu == true }">
        <i class="icon" :class="item.iconClass" />
        <div v-if="dataList != '' && index == 2" class="countIcon">
          {{ dataList.length }}
        </div>
        <div v-if="chatCount > 0 && index == 3" class="countIcon2">
          {{ chatCount }}
        </div>
      </router-link>
      <div class="showMenu" @click="showMenu = !showMenu">
        <img :src="showMenu == false ? require('@/assets/images/footer/icon6@3x.png') : require('@/assets/images/public/icon@3x.png')" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Cookies from "js-cookie";
export default {
  name: "FooterMenu",
  props: {
    typeStatus: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      menuItems: [
        { name: "首页", iconClass: "home", path: "/" },
        {
          name: "积分",
          iconClass: "integralExchange",
          path: "/integralExchange",
        },
        { name: "订单", iconClass: "order", path: "/order" },
        { name: "消息", iconClass: "message", path: "/message" },
        { name: "我的", iconClass: "my", path: "/my" },
      ],
      dataList: [],
      chatCount: 0,
      showMenu: true,
    };
  },
  computed: {
    ...mapState("orders", ["logListDataToFooter"]),
    ...mapState("message", ["msgList"]),
  },
  watch: {
    logListDataToFooter: {
      handler(e) {
        if (e.length == 0) {
          Cookies.remove("buySubmitData");
        }
        if (this.typeStatus == 0) {
          this.dataList = e;
        }
      },
      immediate: true,
    },
    msgList: {
      handler(e) {
        this.chatCount = 0;
        e.forEach((element) => {
          this.chatCount = this.chatCount + element.unreadCount;
        });
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(function() {
      this.init();
    });
  },
  methods: {
    ...mapActions("orders", ["getLogToFooter"]),
    init() {
      this.getStatus();
    },
    getActive(item) {
      return item.iconClass === this.$route.name ? "active" : "";
    },
    getStatus() {
      let data = {
        page: 1,
        limit: 10,
        status: 0,
        type: 0,
      };
      this.getLogToFooter(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  // max-width: 414px;
  position: fixed;
  bottom: 0;

  @media (min-width: 480px) {
    max-width: unset;
  }

  .footer_menu {
    // position: fixed;
    // bottom: 0;
    // left: 0;
    // width: 100%;
    // display: flex;
    // justify-content: space-around;
    // padding-bottom: 0.5rem;
    // padding-top: 0.5rem;
    // background: white;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 0.5rem 0;
    background-color: var(--white);
    border-top: 1px solid var(--light-gray);

    @media (min-width: 480px) {
      display: none;
    }

    &_item {
      color: var(--gray);
      display: flex;
      flex-direction: column;
      font-weight: 600;
      text-decoration: none;
      position: relative;

      .countIcon {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        background-color: var(--red);
        width: 0.9rem;
        height: 0.9rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: bulin 1s 0s infinite;
        color: var(--white);
        z-index: 10;
        font-size: 12px;
      }

      .countIcon2 {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        background-color: var(--red);
        width: 0.9rem;
        height: 0.9rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: bulin 1s 0s infinite;
        color: var(--white);
        z-index: 10;
        font-size: 12px;
      }

      .icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        margin-bottom: 0.5rem;
      }

      .home {
        background: url("../assets/images/public/ic_home_home_icon_gray.webp");
        background-size: 100% 100%;
      }

      .integralExchange {
        background: url("../assets/images/public/ic_home_mall_gray.webp");
        background-size: 100% 100%;
      }

      .order {
        background: url("../assets/images/public/ic_home_order_gray.webp");
        background-size: 100% 100%;
      }

      .message {
        background: url("../assets/images/public/ic_home_information_gray.webp");
        background-size: 100% 100%;
      }

      .my {
        background: url("../assets/images/public/ic_home_mine_gray.webp");
        background-size: 100% 100%;
      }

      &.active {
        color: var(--orange);
        text-decoration: none;

        .home {
          background: url("../assets/images/public/ic_home_bright_color.webp");
          background-size: 100% 100%;
        }

        .integralExchange {
          background: url("../assets/images/public/ic_home_mall_bright_color.webp");
          background-size: 100% 100%;
        }

        .order {
          background: url("../assets/images/public/ic_home_order_color.webp");
          background-size: 100% 100%;
        }

        .message {
          background: url("../assets/images/public/ic_home_information_color.webp");
          background-size: 100% 100%;
        }

        .my {
          background: url("../assets/images/public/ic_home_mine_bright_color.webp");
          background-size: 100% 100%;
        }
      }
    }

    .countIcon {
      position: absolute;
      top: 0;
      right: -1rem;
      background-color: var(--red);
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      animation: bulin 1s 0s infinite;
      color: var(--white);
    }

    .countIcon2 {
      position: absolute;
      top: 0;
      right: -1rem;
      background-color: var(--red);
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      animation: bulin 1s 0s infinite;
      color: var(--white);
    }
  }
}

.footer_menu_row {
  display: none;

  @media (min-width: 480px) {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  @media (min-width: 480px) {
    .showMenu {
      width: 50px;
      height: 50px;
      margin-right: 2rem;
      margin-bottom: 1rem;
      z-index: 100;

      img {
        width: 100%;
      }
    }

    .footer_menu_item {
      color: var(--gray);
      display: flex;
      flex-direction: column;
      font-weight: 600;
      text-decoration: none;
      position: relative;
      transition: 0.3s;
      opacity: 1;

      .icon {
        display: inline-block;
        width: 50px;
        height: 50px;
        margin-bottom: 0.5rem;
      }

      .home {
        background: url("../assets/images/footer/icon@3x.png");
        background-size: 100% 100%;
      }

      .integralExchange {
        background: url("../assets/images/footer/icon2@3x.png");
        background-size: 100% 100%;
      }

      .order {
        background: url("../assets/images/footer/icon3@3x.png");
        background-size: 100% 100%;
      }

      .message {
        background: url("../assets/images/footer/icon4@3x.png");
        background-size: 100% 100%;
      }

      .my {
        background: url("../assets/images/footer/icon5@3x.png");
        background-size: 100% 100%;
      }

      &.active {
        color: var(--orange);
        text-decoration: none;

        .home {
          background: url("../assets/images/footer/icon@3x.png");
          background-size: 100% 100%;
        }

        .integralExchange {
          background: url("../assets/images/footer/icon2@3x.png");
          background-size: 100% 100%;
        }

        .order {
          background: url("../assets/images/footer/icon3@3x.png");
          background-size: 100% 100%;
        }

        .message {
          background: url("../assets/images/footer/icon4@3x.png");
          background-size: 100% 100%;
        }

        .my {
          background: url("../assets/images/footer/icon5@3x.png");
          background-size: 100% 100%;
        }
      }
    }

    .close {
      opacity: 0;
    }
  }
}

@keyframes bulin {
  0% {
    opacity: 0.7;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.7;
  }
}
</style>
